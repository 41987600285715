;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"uLl5tc8tzGoW1bDe1in48"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'

import { ABORT_ROUTE_CHANGE_ERROR_MESSAGE } from './state/use-warn-unsaved-changes'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const iconTags = new Set(['path', 'svg'])

const isString = (value) => typeof value === 'string'

const extractUsefulInformation = (input) => {
  const tagName = input.tagName.toLowerCase() ?? 'no tagname'
  const target = iconTags.has(tagName) ? input.closest('button') : input

  const id = isString(target.id) && target.id.length > 0 ? target.id : undefined
  const testId = target.attributes.getNamedItem('data-testid')?.textContent
  const title = target.attributes.getNamedItem('title')?.textContent
  const ariaDescribedBy = target.attributes.getNamedItem('aria-describedby')?.textContent
  const text =
    isString(target.textContent) && target.textContent.length > 0 ? target.textContent.slice(0, 50) : '[empty node]'
  return {
    attributes: {
      ariaDescribedBy,
      id,
      testId,
      title,
    },
    tagName,
    text,
  }
}

const getBreadcrumbMessage = (info) => {
  const attributes = Object.entries(info.attributes).reduce((accumulator, [key, value]) => {
    return value == null ? accumulator : `${accumulator}#${key}=${value}`
  }, '')
  const text = isString(info.text) && info.text.length > 0 ? `: ${info.text}` : ''

  return `${info.tagName}${attributes}${text}`
}

const enrichUiElementBreadcrumb = (breadcrumb, hint) => {
  try {
    if (breadcrumb.category?.startsWith('ui') && hint?.event.target != null) {
      const target = hint?.event.target
      const info = extractUsefulInformation(target)
      const message = getBreadcrumbMessage(info)
      breadcrumb.message = message
    }
  } catch (error) {
    Sentry.captureMessage('Could not enrich breadcrumbs', { extra: { error } })
  }
  return breadcrumb
}

Sentry.init({
  beforeBreadcrumb(breadcrumb, hint) {
    return enrichUiElementBreadcrumb(breadcrumb, hint)
  },
  beforeSend: (event) => {
    // * Do not send expected errors
    if (event.message === ABORT_ROUTE_CHANGE_ERROR_MESSAGE) {
      return null
    }
    // * check if user had a network connection at time of error
    // ! this is a rather weak check as it only determines if the user was connected to any network,
    // ! not if the network is connected to the internet
    event.tags = {
      ...event.tags,
      onlineStatus: typeof navigator?.onLine === 'boolean' ? (navigator.onLine ? 'online' : 'offline') : '[unknown]',
    }
    return event
  },
  dsn: SENTRY_DSN,
  enabled: process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true',
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
  integrations: [
    Sentry.dedupeIntegration(),
    Sentry.functionToStringIntegration(),
    Sentry.inboundFiltersIntegration(),
    Sentry.extraErrorDataIntegration(),
    Sentry.breadcrumbsIntegration(),
    Sentry.browserApiErrorsIntegration(),
    Sentry.globalHandlersIntegration({ onerror: true, onunhandledrejection: true }),
    Sentry.httpContextIntegration(),
  ],
  // the invasiveness of sentry is annoying, so we disable as much as possible of it
  // we are looking for simple error tracking
  defaultIntegrations: false,
  tracesSampleRate: 0,
  profilesSampleRate: 0,
  replaysSessionSampleRate: 0,
})
